<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs9 sm6 md4 lg3 class="mt-16">
        <v-card class="elevation-0 grey lighten-4 mt-16">
          <p class="display-1 text--primary text-center">
            {{ $t("auth.authorization") }}
          </p>
          <v-form @submit.prevent="authorize">
            <v-card-text>
              <v-text-field
                prepend-icon="mdi-account"
                :error-messages="errors.login"
                name="login"
                :label="attributeLabels.login"
                v-model="login"
                type="text"
              ></v-text-field>
              <v-text-field
                id="password"
                prepend-icon="mdi-lock"
                name="password"
                :label="attributeLabels.password"
                :error-messages="errors.password"
                v-model="password"
                type="password"
              ></v-text-field>
            </v-card-text>
            <v-card-actions class="d-flex justify-space-between">
              <v-btn
                type="submit"
                elevation="0"
                color="blue darken-1"
                text
                class="pa-4"
              >
                {{ $t("auth.login-btn") }}
              </v-btn>
              <router-link
                class="text-body-2 text-decoration-none"
                :to="{ name: 'PasswordRecovery' }"
              >
                {{ $t("auth.forgot_password") }}
              </router-link>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import _ from "lodash";
import { AUTH_REQUEST } from "@/store/actions/identity";

import { required, minLength, maxLength } from "vuelidate/lib/validators";
import validator from "@/mixin/validator";

export default {
  name: "Login",
  mixins: [validator],
  data() {
    return {
      login: "",
      password: "",
      attributeLabels: {
        login: this.$t("auth.login"),
        password: this.$t("auth.password"),
      },
    };
  },
  computed: {
    errors: function () {
      return _.merge(
        {
          login: [],
          password: [],
        },
        this.validator.errors
      );
    },
  },
  methods: {
    authorize: function () {
      if (this.validate()) {
        const { login, password } = this;
        this.$store
          .dispatch(AUTH_REQUEST, { login, password })
          .then(
            (response) => {
              if (response.status === true) {
                const fromRoute = localStorage.getItem("fromRoute");
                const fromRouteQuery = localStorage.getItem("fromRouteQuery");
                this.$router.push(
                  fromRoute
                    ? {
                        path: fromRoute,
                        query: JSON.parse(fromRouteQuery),
                      }
                    : { name: "Dashboard" }
                );
              } else {
                this.validator = {
                  errors: {
                    login: [],
                    password: this.$t("auth.authFailed"),
                  },
                };
                this.validator.watchers["password"] = this.$watch(
                  "password",
                  function () {
                    let errors = JSON.parse(
                      JSON.stringify(this.validator.errors)
                    );
                    _.set(errors, "password", []);
                    this.validator.errors = errors;
                  }
                );
              }
            },
            (err) => {
              console.log("Err", err);
            }
          )
          .catch(() => {});
      }
    },
  },
  validations: {
    login: {
      required,
      minLength: minLength(4),
      maxLength: maxLength(255),
    },
    password: {
      required,
      minLength: minLength(4),
      maxLength: maxLength(255),
    },
  },
};
</script>
